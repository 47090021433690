import { defineComponent } from "vue";
import NavBar from "./NavBar.vue";
export default defineComponent({
  components: {
    NavBar: NavBar
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  setup: function setup() {
    return {};
  }
});