import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent, getCurrentInstance, ref, reactive, onMounted, watch } from "vue"; // import { PlusOutlined } from "@ant-design/icons-vue";

import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import GroupList from "./GroupList";
import TableList from "./TableList";
export default defineComponent({
  name: "",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GroupList: GroupList,
    TableList: TableList
  },
  setup: function setup(props, context) {
    var store = useStore();

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var route = useRoute();
    var skipPath = route.query.path;
    var shown = ref(true); // 退出预览

    var quitDownload = function quitDownload() {
      proxy.$router.back();
    };

    var activeDroupId = ref(0);

    function changeGroup(id) {
      console.log("activeDroupId", id);
      activeDroupId.value = id; // table.value.page = 1;
    }

    watch(function () {
      return props.visible;
    }, function (val) {
      shown.value = val;
    }, {
      immediate: true
    });
    watch(shown, function (val) {
      context.emit("update:visible", val);
    });
    onMounted(function () {
      var _store$state, _store$state$download, _store$state$download2, _store$state2, _store$state2$downloa, _store$state2$downloa2, _store$state3, _store$state3$downloa, _store$state3$downloa2;

      // console.log("store.state?.downloadCenter?.centerUrl?.includes('preview')",store.state?.downloadCenter?.centerUrl?.includes('preview'));
      if ((_store$state = store.state) !== null && _store$state !== void 0 && (_store$state$download = _store$state.downloadCenter) !== null && _store$state$download !== void 0 && (_store$state$download2 = _store$state$download.centerUrl) !== null && _store$state$download2 !== void 0 && _store$state$download2.includes('planet') || (_store$state2 = store.state) !== null && _store$state2 !== void 0 && (_store$state2$downloa = _store$state2.downloadCenter) !== null && _store$state2$downloa !== void 0 && (_store$state2$downloa2 = _store$state2$downloa.centerUrl) !== null && _store$state2$downloa2 !== void 0 && _store$state2$downloa2.includes('preview')) {
        store.dispatch('groupName/changeDownloadGroupId', 1);
      } else if ((_store$state3 = store.state) !== null && _store$state3 !== void 0 && (_store$state3$downloa = _store$state3.downloadCenter) !== null && _store$state3$downloa !== void 0 && (_store$state3$downloa2 = _store$state3$downloa.centerUrl) !== null && _store$state3$downloa2 !== void 0 && _store$state3$downloa2.includes('analyse')) {
        store.dispatch('groupName/changeDownloadGroupId', 3);
      }
    });
    return {
      store: store,
      quitDownload: quitDownload,
      activeDroupId: activeDroupId,
      changeGroup: changeGroup,
      shown: shown
    };
  }
});