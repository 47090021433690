import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
// import CreateGroup from "../components/CreateGroup";
import { defineComponent, ref, onBeforeMount, onMounted, reactive } from "vue"; // import { PlusOutlined } from '@ant-design/icons-vue';

import { getGroupList, deleteGroup } from "@/api/template-market";
import useEmitter from "@/composables/useEmitter";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "DownloadGroupList",
  props: {// groupList: {
    //   type: Array,
    //   required: true,
    // },
  },
  components: {},
  setup: function setup(props, context) {
    var store = useStore();
    var route = useRoute();
    var skipPath = route.query.path; // const emitter = useEmitter()
    // const groupList = ref([]);

    var activeId = ref(0); // const visible = ref(false);
    // const visibleDelete = ref(false);
    // const loading = ref(false);
    // const groupInfo = ref({});
    // const isAll = ref(0);
    // const radioStyle = reactive({
    //   display: "block",
    //   height: "30px",
    //   lineHeight: "30px",
    // });

    function changeGroup(item) {
      activeId.value = item.id;

      if (item.id === 0) {
        context.emit("change-group", 1);
        store.dispatch('groupName/changeDownloadGroupId', 1);
      }

      if (item.id === 1) {
        context.emit("change-group", 3);
        store.dispatch('groupName/changeDownloadGroupId', 3);
      }
    }
    /** 获取分组列表 */
    // async function getGroups() {
    //   try {
    //     const { data } = await getGroupList({type: 1});
    //     console.log(props.groupList)
    //     // eslint-disable-next-line vue/no-mutating-props
    //     props.groupList.length = 0
    //     // eslint-disable-next-line vue/no-mutating-props
    //     props.groupList.push(...data)
    //     if (props.groupList.every(item => item.id !== activeId.value)) {
    //       changeGroup({id: 0});
    //     }
    //   } catch (error) {
    //     context.message.error(
    //       error.data?.message || error.message || "服务器错误"
    //     );
    //   }
    // }

    /** 删除分组 */
    // async function deleteSur() {
    //   const params = {
    //     id: groupInfo.value.id,
    //   };
    //   if (groupInfo.value.surveys_count > 0) {
    //     params.is_all = isAll.value;
    //   }
    //   try {
    //     await deleteGroup(params);
    //     visibleDelete.value = false;
    //     getGroups();
    //   } catch (error) {
    //     context.message.error(
    //       error.data?.message || error.message || "服务器错误"
    //     );
    //   }
    // }
    // onBeforeMount(() => {
    //   getGroups();
    // });


    onMounted(function () {
      var _store$state, _store$state$download, _store$state$download2, _store$state2, _store$state2$downloa, _store$state2$downloa2;

      //   emitter.on('addGroup',()=>{
      //     visible.value = true; groupInfo.value = {}
      //   })
      if ((_store$state = store.state) !== null && _store$state !== void 0 && (_store$state$download = _store$state.downloadCenter) !== null && _store$state$download !== void 0 && (_store$state$download2 = _store$state$download.centerUrl) !== null && _store$state$download2 !== void 0 && _store$state$download2.includes('planet')) {
        activeId.value = 0;
      } else if ((_store$state2 = store.state) !== null && _store$state2 !== void 0 && (_store$state2$downloa = _store$state2.downloadCenter) !== null && _store$state2$downloa !== void 0 && (_store$state2$downloa2 = _store$state2$downloa.centerUrl) !== null && _store$state2$downloa2 !== void 0 && _store$state2$downloa2.includes('analyse')) {
        activeId.value = 1;
      }
    });
    return {
      //   getGroups,
      changeGroup: changeGroup,
      //   deleteSur,
      //   radioStyle,
      //   loading,
      // groupList,
      activeId: activeId //   visible,
      //   visibleDelete,
      //   groupInfo,
      //   isAll,
      //   store,

    };
  }
});