import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eaa0d5aa"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "phone"
};
var _hoisted_2 = {
  class: "page"
};
var _hoisted_3 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_nav_bar = _resolveComponent("nav-bar");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_nav_bar, {
    title: _ctx.title
  }, null, 8, ["title"]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])])]);
}