import request from '@/utils/request'

/* 下载中心列表 */
export function getDownloadList(sn, module, params) {
  return request({
    url: `/console/surveys/${sn}/download_center/${module}`,
    method: 'GET',
    params
  })
}

/* 下载中心添加 */
export function addDownloadCenter(sn, data) {
  return request({
    method: "POST",
    url: `/console/surveys/${sn}/download_center`,
    data,
  });
}

