import request from '@/utils/request'

// 保存问卷模版
export function saveTemplate(sn, data) {
  return request({
    url: `/console/surveys/${sn}/templates`,
    method: 'post',
    data
  })
}

/* 获取分组列表 */
export function getGroupList(params) {
  return request({
    method: "GET",
    url: '/console/survey_groups',
    params,
  });
}
/* 新增分组 */
export function postGroup(data) {
  return request({
    method: "POST",
    url: '/console/survey_groups',
    data,
  });
}

/* 删除分组 */
export function deleteGroup(data) {
  return request({
    method: "delete",
    url: `/console/survey_groups/${data.id}`,
    data: {
      is_all: data.is_all
    }
  });
}

/* 重命名分组 */
export function renewGroupName(data) {
  return request({
    method: "patch",
    url: `/console/survey_groups/${data.id}/title`,
    params: {
      title: data.title
    }
  });
}

/* 移动问卷分组 */
export function moveSurvey(data) {
  return request({
    method: "PATCH",
    url: `/console/surveys/${data.sn}/group`,
    data: {
      group_id: data.groupIds,
    },
  });
}

/* 移动模版分组 */
export function moveTemplate(sn, id) {
  return request({
    method: "PATCH",
    url: `/console/surveys/${sn}/template_group`,
    data: {
      group_id: id,
    },
  });
}


/* 模板列表 */
export function getTemplateList(params) {
  return request({
    method: "get",
    url: '/console/survey_templates',
    params,
  });
}

/* 模版重命名 */
export function renameTemplate(sn, data) {
  return request({
    method: 'patch',
    url: `/console/templates/${sn}/title`,
    data
  })
}

/* 删除模版 */

export function delTemplate(sn) {
  return request({
    method: 'delete',
    url: `/console/templates/${sn}`,
  })
}


