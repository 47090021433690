import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-abf2f0de"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "pro-cont"
};
var _hoisted_2 = {
  class: "pro-title"
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_pagination = _resolveComponent("a-pagination");

  var _component_a_table = _resolveComponent("a-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.activeDownloadGroupId === 1 ? '问卷设计' : '数据分析'), 1), _createVNode(_component_a_table, {
    class: "pro-table custom-table",
    columns: _ctx.columns,
    "data-source": _ctx.tableData,
    pagination: false,
    scroll: {
      y: 'calc(100vh - 305px)'
    },
    rowKey: function rowKey(record, index) {
      return record.id;
    }
  }, {
    file_size: _withCtx(function (_ref) {
      var record = _ref.record;
      return [_createElementVNode("div", null, _toDisplayString(!record.file_size ? '-' : record.file_size), 1)];
    }),
    status_str: _withCtx(function (_ref2) {
      var record = _ref2.record;
      return [record.status === 2 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: function onClick($event) {
          return _ctx.clickDownload(record);
        },
        class: "blue"
      }, _toDisplayString(record.status_str), 9, _hoisted_3)) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["status-text", record.status === 0 || record.status === 1 ? 'green' : record.status === 3 ? 'red' : record.status === 4 ? 'gray' : ''])
      }, _toDisplayString(record.status_str), 3))];
    }),
    footer: _withCtx(function () {
      return [_createVNode(_component_a_pagination, {
        class: "pagination",
        "show-size-changer": "",
        current: _ctx.page,
        "onUpdate:current": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.page = $event;
        }),
        pageSize: _ctx.pageSize,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.pageSize = $event;
        }),
        defaultPageSize: _ctx.pageSize,
        "onUpdate:defaultPageSize": _cache[2] || (_cache[2] = function ($event) {
          return _ctx.pageSize = $event;
        }),
        total: _ctx.total,
        onChange: _ctx.onPaginationChange,
        onShowSizeChange: _ctx.onShowSizeChange
      }, null, 8, ["current", "pageSize", "defaultPageSize", "total", "onChange", "onShowSizeChange"])];
    }),
    _: 1
  }, 8, ["columns", "data-source", "scroll", "rowKey"])]);
}