import request from '@/utils/request'

// 发布问卷
export function publishSurvey(data) {
    return request({
        url: `/console/survey_publishes`,
        method: 'post',
        data
    })
}

// 问卷详情
export function getSurveyInfo(sn) {
    return request({
        url: `/console/surveys/${sn}`,
        method: 'get',
    })
}

// 查看二维码
export function getQrcode(sn) {
    return request({
        url: `/console/survey_publishes/${sn}/qrcode`,
        method: 'get'
    })
}
