import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-690d51be"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "group-wrap"
};
var _hoisted_2 = {
  class: "group-list scrollbar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["groups", _ctx.activeId === 0 ? 'active' : '']),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.changeGroup({
        id: 0
      });
    })
  }, "问卷设计", 2), _createElementVNode("div", {
    class: _normalizeClass(["groups", _ctx.activeId === 1 ? 'active' : '']),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.changeGroup({
        id: 1
      });
    })
  }, "数据分析", 2)])]);
}