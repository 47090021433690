import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-691cf557"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "layout"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "left"
};
var _hoisted_4 = {
  class: "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_GroupList = _resolveComponent("GroupList");

  var _component_TableList = _resolveComponent("TableList");

  var _component_a_modal = _resolveComponent("a-modal");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_a_modal, {
    visible: _ctx.shown,
    "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.shown = $event;
    }),
    title: "下载中心",
    destroyOnClose: true,
    footer: null,
    width: "100%",
    wrapClassName: "full-modal"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_GroupList, {
        onChangeGroup: _ctx.changeGroup
      }, null, 8, ["onChangeGroup"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_TableList, {
        "group-id": _ctx.activeDroupId
      }, null, 8, ["group-id"])])])])];
    }),
    _: 1
  }, 8, ["visible"])]);
}